<template>
  <div class="reference view">
    <AdminPanel :title="'레퍼런스'">
      <div class="list">
        <div style="text-align: right; margin: 10px auto">
          <el-button
            type="primary"
            size="small"
            @click="openReferenceDialog(null)"
            icon="el-icon-plus"
            >레퍼런스 생성</el-button
          >
        </div>
        <el-table
          empty-text="검색한 정보가 없습니다"
          v-loading="loading"
          element-loading-text="loading"
          ref="table"
          :data="referenceList"
          style="width: 100%"
        >
          <el-table-column
            v-if="!$store.state.isMobile"
            width="100"
            prop="id"
            label="ID"
          >
          </el-table-column>
          <el-table-column prop="title" min-width="200" label="제목">
          </el-table-column>
          <el-table-column
            v-if="!$store.state.isMobile"
            prop="create_time"
            label="생성 날짜"
          >
            <template slot-scope="scope">
              {{ scope.row.create_time | localtime }}
            </template>
          </el-table-column>
          <el-table-column width="100" prop="visible" label="공개 여부">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.visible"
                active-text=""
                inactive-text=""
                @change="handleVisibleSwitch(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="옵션" width="200">
            <div slot-scope="scope">
              <icon-btn
                name="수정"
                icon="edit"
                @click.native="openReferenceDialog(scope.row.id)"
              ></icon-btn>
              <icon-btn
                name="삭제"
                icon="delete"
                @click.native="deleteReference(scope.row.id)"
              ></icon-btn>
              <icon-btn
                name="해당 페이지로 이동"
                icon="link"
                @click.native="goReferenceDetail(scope.row.id)"
              ></icon-btn>
            </div>
          </el-table-column>
        </el-table>
        <div class="panel-options">
          <el-button
            type="primary"
            size="small"
            @click="openReferenceDialog(null)"
            icon="el-icon-plus"
            >레퍼런스 생성</el-button
          >
          <el-pagination
            v-if="!contestID"
            class="page"
            layout="prev, pager, next"
            @current-change="currentChange"
            :page-size="pageSize"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </AdminPanel>
    <!-- 강사님 자료 수정 다이얼로그-->
    <el-dialog
      :class="$store.state.isMobile ? 'mobile-dialog' : ''"
      :width="$store.state.isMobile ? '100vw' : '70%'"
      top="50px"
      :title="referenceDialogTitle"
      :visible.sync="showEditReferenceDialog"
      @open="onOpenEditDialog"
      :close-on-click-modal="false"
    >
      <el-form label-position="top">
        <el-form-item :label="'제목'" for="제목" required>
          <el-input
            id="제목"
            v-model="reference.title"
            :placeholder="'제목'"
            class="title-input"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="'내용'" required>
          <VueCkEditor
            :data="reference.content"
            @setContent="setContent"
          ></VueCkEditor>
        </el-form-item>
        <div class="visible-box">
          <!--
          <span>상태</span>
          -->
          <el-switch
            v-model="reference.visible"
            active-text="공개"
            inactive-text="비공개"
          >
          </el-switch>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <cancel @click.native="showEditReferenceDialog = false"></cancel>
        <save type="primary" @click.native="submitReference"></save>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api.js";
import AdminPanel from "@admin/components/AdminPanel.vue";
import VueCkEditor from "../../../admin/components/VueCkEditor.vue";
export default {
  name: "Reference",
  components: {
    AdminPanel,
    VueCkEditor,
  },
  data() {
    return {
      contestID: "",
      // 다이얼로그 표시 여부
      showEditReferenceDialog: false,
      // reference 목록
      referenceList: [],
      // 한 페이지에 표시되는 reference 수
      pageSize: 15,
      // 총 reference 수
      total: 0,
      // 현재 reference ID
      currentReferenceId: null,
      mode: "create",
      // reference (신규 | 편집) 모델
      reference: {
        title: "",
        visible: true,
        content: "",
      },
      // reference 제목
      referenceDialogTitle: "",
      // 표시여부loading
      loading: true,
      // 현재 페이지 번호
      currentPage: 0,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getReferenceList();
    },
    currentChange() {
      this.getReferenceList();
    },
    getReferenceList() {
      this.loading = true;
      console.log("start get data");
      api
        .getReferenceList()
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.referenceList = res.data.data;
          console.log("Success to accept data.");
        })
        .catch(() => {
          this.loading = false;
          console.log("Fail to accept data.");
        });
    },
    // 편집 대화 상자를 열기위한 콜백
    onOpenEditDialog() {
      // 할일 최적화
      // 일시적으로 텍스트 편집기 디스플레이 비정상 버그 해결g
      setTimeout(() => {
        if (document.createEvent) {
          let event = document.createEvent("HTMLEvents");
          event.initEvent("resize", true, true);
          window.dispatchEvent(event);
        } else if (document.createEventObject) {
          window.fireEvent("onresize");
        }
      }, 0);
    },
    // 수정 제출
    // MouseEvent는 기본적으로 전달됩니다.
    submitReference(data = undefined) {
      let funcName = "";
      if (!data.title) {
        data = {
          id: this.currentReferenceId,
          title: this.reference.title,
          content: this.reference.content,
          visible: this.reference.visible,
        };
      }
      funcName = this.mode === "edit" ? "updateReference" : "createReference";
      api[funcName](data)
        .then((res) => {
          this.showEditReferenceDialog = false;
          this.init();
        })
        .catch();
    },
    // Announcement 삭제
    deleteReference(referenceId) {
      this.$confirm("정말로 삭제하시겠습니까?", "경고", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        type: "warning",
      })
        .then(() => {
          // then 확실히
          this.loading = true;
          let funcName = "deleteReference";
          api[funcName](referenceId).then((res) => {
            this.loading = true;
            this.init();
          });
        })
        .catch(() => {
          // catch 취소
          this.loading = false;
        });
    },
    openReferenceDialog(id) {
      this.showEditReferenceDialog = true;
      if (id !== null) {
        this.currentReferenceId = id;
        this.referenceDialogTitle = "레퍼런스";
        this.referenceList.find((item) => {
          if (item.id === this.currentReferenceId) {
            this.reference.title = item.title;
            this.reference.visible = item.visible;
            this.reference.content = item.content;
            this.mode = "edit";
          }
        });
      } else {
        this.referenceDialogTitle = "레퍼런스";
        this.reference.title = "";
        this.reference.visible = true;
        this.reference.content = "";
        this.mode = "create";
      }
    },
    goReferenceDetail(id) {
      window.open(`${window.location.origin}/reference/${id}`);
    },
    handleVisibleSwitch(row) {
      console.log(row);
      this.mode = "edit";
      this.submitReference({
        id: row.id,
        title: row.title,
        content: row.content,
        visible: row.visible,
      });
    },
    setContent(e) {
      this.reference.content = e;
    },
  },
  watch: {
    $route() {
      this.init();
    },
  },
};
</script>

<style lang="less">
.title-input {
  margin-bottom: 20px;
}

.visible-box {
  margin-top: 10px;
  width: 205px;
  float: left;
}
.mobile-dialog > .el-dialog {
  position: absolute !important;
  left: 5px !important;
}
</style>
