export const m = {
  // SideMenu.vue
  Dashboard: '대시보드',
  General: '기본설정',
  User: '회원정보',
  // 공지사항 ->new로 변경 0316
  Announcement: 'NEWS',
  System_Config: '시스템 설정',
  Judge_Server: '채점 서버',
  Prune_Test_Case: '테스트 케이스 가지치기',
  Problem: '문제',
  Problem_List: '문제 목록',
  Create_Problem: '문제 만들기',
  Export_Import_Problem: 'Export/Import',
  Contest: '집합교육',
  Contest_List: '집합교육 목록',
  Create_Contest: '강의 생성하기',
  // User.vue
  User_User: '회원정보',
  Import_User: '회원등록',
  Generate_User: '회원생성',
  // User.vue-dialog
  User_Info: '회원정보',
  User_Username: '아이디',
  User_Real_Name: '이름',
  User_Email: '이메일 주소',
  User_New_Password: '새 비밀번호',
  User_Type: '회원정보 유형',
  Problem_Permission: '문제 승인',
  Two_Factor_Auth: '2 Factor 인증',
  Is_Disabled: '비활성화',
  SolveProblem: '해결한 문제',
  // Announcement.vue
  General_Announcement: '공지사항',
  Announcement_Title: '제목',
  Announcement_Content: '내용',
  Announcement_Visible: '상태',
  // Reference.vue
  General_Reference: '공지사항',
  Reference_Title: '제목',
  Reference_Content: '내용',
  Reference_Visible: '상태',
  // Conf.vue
  SMTP_Config: 'SMTP 설정',
  Server: '서버',
  Port: '포트',
  Email: '이메일',
  Password: '비밀번호',
  Website_Config: '웹 설정',
  Base_Url: '기본 URL',
  Name: '이름',
  Shortcut: '바로가기',
  Footer: '푸터',
  Allow_Register: '등록 허용',
  Submission_List_Show_All: '제출 목록 전체보기',
  // JudgeServer.vue
  Judge_Server_Token: '채점 서버 토큰',
  Judge_Server_Info: '채점 서버',
  IP: '아이피',
  Judger_Version: '채점 시스템 버전',
  Service_URL: '서비스 URL',
  Last_Heartbeat: '최근 Heartbeat',
  Create_Time: '시간 생성하기',
  // PruneTestCase
  Test_Case_Prune_Test_Case: '테스트 케이스 가지치기',
  // Problem.vue
  Display_ID: '번호',
  Title: '제목',
  Description: '설명',
  Input_Description: '입력 설명',
  Output_Description: '출력 설명',
  Time_Limit: '시간 제한',
  Memory_limit: '메모리 제한',
  Difficulty: '난이도',
  Visible: '보이기 설정',
  Languages: '언어',
  Input_Samples: '입력 예시',
  Output_Samples: '출력 예시',
  Add_Sample: '샘플 추가',
  Code_Template: '코드 템플릿',
  Special_Judge: '스페셜 저지',
  Use_Special_Judge: '스페셜 저지 사용',
  Special_Judge_Code: '스페셜 저지 코드',
  SPJ_language: 'SPJ 언어',
  Compile: '컴파일',
  TestCase: '테스트 케이스',
  Type: '유형',
  Input: '입력',
  Output: '출력',
  Score: '점수',
  Hint: '힌트',
  Source: '소스코드',
  Edit_Problem:'문제 수정',
  Tag: '태그',
  New_Tag: '새로운 태그',
  ShareSubmission: '공유 제출',
  You_have_solved_the_problem: '이미 정답을 작성하였습니다.',
  // Problemlist
  Contest_Problem_List: '강의 문제 목록',
  // Contest.vue
  ContestTitle: '제목',
  ContestDescription: '설명',
  Contest_Start_Time: '시작 시간',
  Contest_End_Time_Check: '종료 시간 (한번 더 확인해주세요.)',
  Contest_End_Time: '종료 시간',
  Contest_Password: '비밀번호',
  Contest_Rule_Type: '대회 규칙 유형',
  Real_Time_Rank: '실시간 랭킹',
  Contest_Status: '대회 상태',
  Allowed_IP_Ranges: '허용된 IP 범위',
  CIDR_Network: 'CIDR 네트워크',
  // Dashboard.vue
  Last_Login: '최근 로그인',
  System_Overview: '시스템 개요',
  DashBoardJudge_Server: '채점 서버',
  HTTPS_Status: 'HTTPS 상태',
  Force_HTTPS: '강제 HTTPS',
  CDN_HOST: 'CDN 호스트',

  // not admin part
  Contest_Announcements: '강사님 자료',
  Contest_References: '레퍼런스',

  // NavBar.vue, not admin part
  Home: 'Home',
  Category: 'Level별 문제',
  NavProblems: '문제은행',
  Contests: '집합교육',
  NavStatus: '채점결과',
  Rank: '랭킹',
  ACM_Rank: 'ACM 랭킹',
  OI_Rank: 'OI 랭킹',
  About: '읽어보세요',
  G1_name: '[G1] 시작반 1',
  G2_name: '[G2] 시작반 2',
  G3_name: '[G3] 훈련반 1',
  G4_name: '[G4] 훈련반 2',
  G5_name: '[G5] 심화반 1',
  G6_name: '[G6] 심화반 2',
  Judger: '채점 시스템 정보',
  FAQ: '소스코드 제출방법',
  Login: '로그인',
  Register: '회원가입',
  MyHome: 'My홈',
  MySubmissions: 'My채점결과',
  Settings: '내 정보수정',
  Management: '관리자페이지',
  Typing: '타자연습',
  Logout: '로그아웃',
  Welcome_to: 'Welcome to'
}
