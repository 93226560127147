<template>
  <Panel class="panel-container" shadow :padding="10">
    <div slot="title">
      {{ title }}
    </div>
    <div slot="extra">
      <Button
        :class="$store.state.isMobile ? 'tutor-code-register-button' : ''"
        v-if="isAdminRole"
        type="info"
        @click="onOpenEditDialog"
        >레퍼런스 수정</Button
      >
    </div>

    <div>
      <BoardHighlight
        v-katex
        :code="content"
        key="content"
        class="content-container markdown-body"
      ></BoardHighlight>
    </div>

    <!-- 강사님 자료 수정 다이얼로그 -->
    <el-dialog
      title="레퍼런스 수정"
      :visible.sync="showEditReferenceDialog"
      @open="onOpenEditDialog"
      :close-on-click-modal="false"
    >
      <el-form label-position="top">
        <el-form-item
          :label="$t('m.Reference_Title')"
          for="$t('m.Reference_Title')"
          required
        >
          <el-input
            id="$t('m.Reference_Title')"
            v-model="tutor.title"
            :placeholder="$t('m.Reference_Title')"
            class="title-input"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('m.Reference_Content')" required>
          <VueCkEditor
            :data="tutor.content"
            @setContent="setContent"
          ></VueCkEditor>
        </el-form-item>
        <div class="visible-box">
          <el-switch
            v-model="tutor.visible"
            active-text="공개"
            inactive-text="비공개"
          >
          </el-switch>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click.native="showEditReferenceDialog = false"
          >Cancel</el-button
        >
        <el-button type="primary" @click.native="submitTutorCode"
          >저장</el-button
        >
      </span>
    </el-dialog>
  </Panel>
</template>

<script>
import api from "@oj/api";
import adminApi from "@admin/api";
import Pagination from "@oj/components/Pagination";
import { mapGetters, mapActions, mapState } from "vuex";
import BoardHighlight from "@/pages/oj/components/BoardHighlight";
import VueCkEditor from "@/pages/admin/components/VueCkEditor.vue";
export default {
  name: "ReferenceDetail",
  components: {
    Pagination,

    BoardHighlight,
    VueCkEditor,
  },
  data() {
    return {
      showEditReferenceDialog: false,
      limit: 10,
      total: 10,
      btnLoading: false,
      tutor: {
        title: "",
        visible: true,
        content: "",
      },
      reference: "",
    };
  },
  mounted() {
    this.getReference();
    this.getProfile();
  },
  methods: {
    ...mapActions(["getProfile", "changeModalStatus"]),
    setContent(e) {
      this.tutor.content = e;
    },
    getReference() {
      api
        .getReferenceDetail(this.$route.params.referenceID)
        .then((res) => {
          this.reference = res.data.data;
          this.tutor.title = this.reference.title;
          this.tutor.content = this.reference.content;
          this.tutor.visible = this.reference.visible;
        })
        .catch((error) => {
          console.log("error in getReference -> ", error);
        });
    },
    async submitTutorCode() {
      // superAdmin일때만
      // http://localhost:8081/api/admin/reference
      // 데이터가 없으면 post
      // 데이터가 있으면 put

      this.showEditReferenceDialog = true;
      // 제출 -> 갱신
      const data = {
        id: this.reference.id,
        title: this.tutor.title,
        content: this.tutor.content,
        visible: this.tutor.visible,
      };

      adminApi.updateReference(data).then((res) => {
        this.showEditReferenceDialog = false;
        // 변경사항 적용
        this.getReference();
      });
    },
    onOpenEditDialog() {
      this.showEditReferenceDialog = true;
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["user", "isAuthenticated", "isAdminRole", "isAdminRole"]),
    title() {
      return this.reference ? this.reference.title : "로딩 중";
    },
    content() {
      return this.reference ? this.reference.content : "로딩 중";
    },
    visible() {
      return this.tutor ? this.tutor.visible : "로딩 중";
    },
    isLoaded() {
      return this.reference ? true : false;
    },
    isContest() {
      return !!this.$route.params.contestID;
    },
  },
};
</script>

<style scoped lang="less">
.panel-container {
  margin-top: 20px;
  position: relative;
}

.content-container {
  padding: 0 20px 20px 20px;
}

changeLocale .reference-animate-enter-active {
  animation: fadeIn 1s;
}
.tutor-code-register-button {
  position: absolute;
  top: -11px;
  right: -12px;
}
</style>
