<template>
  <Panel class="panel-container" shadow :padding="10">
    <div slot="title">
      {{ title }}
    </div>
    <div slot="extra" v-if="listVisible">
      <Button v-if="isAdminRole" type="info" @click="moveTutorCode"
        >레퍼런스 작성/수정</Button
      >
    </div>

    <Table
      class="lecture-reference"
      @on-row-click="goRoute"
      :columns="columns"
      :data="references"
      key="list"
    ></Table>
  </Panel>
</template>

<script>
import moment from "moment";

import api from "@oj/api";
import Pagination from "@oj/components/Pagination";
import { mapGetters, mapActions, mapState } from "vuex";
import BoardHighlight from "@/pages/oj/components/BoardHighlight";

export default {
  name: "Reference",
  components: {
    Pagination,

    BoardHighlight,
  },
  data() {
    return {
      limit: 10,
      total: 10,
      btnLoading: false,
      columns: [
        {
          title: "글 번호",
          width: "20%",
          align: "center",
          render: (h, params) => {
            return h("span", params.row.id);
          },
        },
        {
          title: "제목",
          width: "80%",
          align: "left",
          resizable: true,
          render: (h, params) => {
            return h("span", params.row.title, {});
          },
        },
      ],
      references: [],
      reference: "",
      listVisible: true,
    };
  },
  mounted() {
    this.init();
    this.getProfile();
  },
  methods: {
    ...mapActions(["getProfile", "changeModalStatus"]),
    init() {
      this.getContestReferenceList();
    },
    getContestReferenceList() {
      this.btnLoading = true;
      api.getAllReference().then(
        (res) => {
          this.btnLoading = false;
          this.references = res.data.data;
        },
        () => {
          this.btnLoading = false;
        }
      );
    },
    moveTutorCode() {
      window.open(`/admin/reference`);
    },
    goRoute(row) {
      this.$router.push({
        name: "enterprise-contest-reference-details",
        params: { referenceID: row.id },
      });
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["user", "isAuthenticated", "isAdminRole", "isAdminRole"]),
    title() {
      return "레퍼런스";
    },
    isContest() {
      return !!this.$route.params.contestID;
    },
  },
};
</script>

<style lang="less">
.panel-container {
  margin-top: 20px;
  position: relative;
}

.iview-table-cell {
  padding: 0;
}
.references-container {
  margin-top: -10px;
  margin-bottom: 10px;
  li {
    padding-top: 15px;
    list-style: none;
    padding-bottom: 15px;
    margin-left: 20px;
    font-size: 16px;
    border-bottom: 1px solid rgba(187, 187, 187, 0.5);
    &:last-child {
      border-bottom: none;
    }
    .flex-container {
      .title {
        flex: 1 1;
        text-align: left;
        padding-left: 10px;
        a.entry {
          color: #495060;
          &:hover {
            color: #2d8cf0;
            border-bottom: 1px solid #2d8cf0;
          }
        }
      }
      .creator {
        flex: none;
        width: 200px;
        text-align: center;
      }
      .date {
        flex: none;
        width: 200px;
        text-align: center;
      }
    }
  }
}

changeLocale .reference-animate-enter-active {
  animation: fadeIn 1s;
}
.lecture-reference .ivu-table-header table th {
  text-align: center !important;
}
</style>
