<template>
  <Row>
    <Panel shadow>


      <div v-if="problemStepLevelList" slot="title">
        {{ problemStepLevelList.content }}
      </div>

      <div v-if="problemStepLevelList" slot="title">
        {{ startTitle }} ~ {{ endTitle }}
      </div>

      <div style="padding-left: 29px" v-if="isAdminRole">
        <div>
          <a @click="stepEdit">스텝 수정하기</a>
        </div>
        <div>
          <a @click="levelEdit">레벨 리스트 추가하기</a>
        </div>
      </div>
      <div v-else slot="title">문제 목록</div>
      <BoardHighlight
        class="bg-code ck-content"
        :code="problemStepLevelList.content_detail"
      ></BoardHighlight>
      
      <Table
        border
        @on-row-click="move"
        :columns="columns"
        :data="problemStepLevelList['problem_step']"
        data-testid="table-problem-list"
      >
      </Table>
    </Panel>
  </Row>
</template>
<script>
import store, { types } from "../../../../store";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import api from "../../api";

import BoardHighlight from "../../components/BoardHighlight.vue";

export default {
  mixins: [],
  data() {
    return {
      problemStepLevelList: [],
      columns: [],
      startTitle: "",
      endTitle: "",
    };
  },
  async mounted() {
    this.getProfile();
    if (!this.isLoggedIn()) {
      this.$router.push("/");
      this.$error("권한이 없습니다.");
    }
    // -> 그 레벨이 맞는 problemStepLevel 리스트 호출
    if (this.isAdminRole) {
      this.changeTableView();
    }
    // 이제 여기서 list에 대한 정보 + 가져와야함
    // 표에 매핑
  },
  computed: {
    ...mapState(["problemTitle", "utils", "user"]),
    ...mapGetters(["isAdminRole", "isAdminRole"]),
  },
  created() {
    this.category = this[this.$route.query.type];
    this.changeTableView();
  },
  watch: {
    "$route.params.step_id"(to, from) {
      let isValidEmail = false;
      let isValidAuth = false;
      const profile = store.getters.profile;
      if (profile && profile.user.email && profile["mincoding_auth"]) {
        if (!profile["mincoding_auth"]["verify_email"]) {
          isValidEmail = true;
        }
        if (profile["mincoding_auth"]["verify_email"]) {
          if (
            profile.user.email.indexOf(
              profile["mincoding_auth"]["verify_email"]
            ) > -1
          ) {
            isValidEmail = true;
          }
        }
        if (
          profile.user.email.indexOf("@zigbang.com") > -1 &&
          profile.github.toLowerCase() === "sds"
        ) {
          isValidEmail = true;
        }
      }
      // 그룹 권한 인증
      let mincodingAuth = [];
      if (
        profile["mincoding_auth"] &&
        profile["mincoding_auth"]["secret_code"].length
      ) {
        mincodingAuth = profile["mincoding_auth"]["secret_code"].map(
          (li) => li.problem_step.id
        );
      }
      // 그룹권한은 이메일 권한도 같이 있어야한다
      if (mincodingAuth.includes(Number(to)) && isValidEmail) {
        isValidAuth = true;
      }
      // 유저 권한 인증
      let userAuth = [];
      if (profile["user_auth"] && profile["user_auth"].length) {
        userAuth = profile["user_auth"].map((li) => li.problem_step.id);
      }
      if (userAuth.includes(Number(to))) {
        isValidAuth = true;
      }

      if (store.getters.isAdminRole || store.getters.isAdminRole) {
        this.category = this[this.$route.query.type];
        this.changeTableView();
      } else if (isValidAuth) {
        this.category = this[this.$route.query.type];
        this.changeTableView();
      } else {
        
        window.location.href = window.location.origin;
        
        alert("권한이 존재하지 않습니다.");
      }
    },
  },
  methods: {
    ...mapActions(["getProfile"]),
    ...mapMutations(["CHANGE_PROBLEM_TITLE"]),
    async changeTableView() {
      await api.getUserInfo().then((res) => {
        this.$store.commit(types.CHANGE_PROFILE, { profile: res.data.data });
      });
      const { step_id } = this.$route.params;
      const { data } = await api.getProblemStep(step_id);
      this.problemStepLevelList = data["data"]["data"];
      this.startTitle = this.problemStepLevelList.problem_step[0]
        ? this.problemStepLevelList.problem_step[0].title
        : "";
      this.endTitle = this.problemStepLevelList.problem_step[0]
        ? this.problemStepLevelList.problem_step[
            this.problemStepLevelList.problem_step.length - 1
          ].title
        : "";
 
      const acm =
        this.$store.state.user.profile["acm_problems_status"]["problems"];
      const oi =
        this.$store.state.user.profile["oi_problems_status"]["problems"];
      let acmList = null;
      let list = [];
      if (acm) {
        acmList = Object.values(acm)
          .filter((li) => li.status === 0)
          .map((li) => li._id);
        list = [...acmList];
        
      }
      let oiList = null;
      if (oi) {
        oiList =
          oi &&
          Object.values(oi)
            .filter((li) => li.status === 0)
            .map((li) => li._id);
        list = [...list, ...oiList];
      }
      if (list) {
        
        list = new Set(list);
        list = Array.from(list);
        
      }

      this.problemStepLevelList &&
        this.problemStepLevelList.problem_step &&
        this.problemStepLevelList.problem_step.length &&
        this.problemStepLevelList.problem_step.reduce((acc, cur, index) => {
     
          let count = 0;

        
          if (cur && cur.problem_step_level && cur.problem_step_level.length) {
            for (let y = 0; y < cur.problem_step_level.length; y++) {
              let solveAcmProblem;
              let solveOiProblem;
              let status;
    
              if (
                this.$store.state.user.profile.acm_problems_status.problems &&
                cur.problem_step_level[y].visible
              ) {
                solveAcmProblem =
                  this.$store.state.user.profile.acm_problems_status.problems[
                    cur.problem_step_level[y].problem.id
                  ];
              }

              if (
                this.$store.state.user.profile.oi_problems_status.problems &&
                cur.problem_step_level[y].visible
              ) {
                solveOiProblem =
                  this.$store.state.user.profile.oi_problems_status.problems[
                    cur.problem_step_level[y].problem.id
                  ];
              }

              if (solveAcmProblem) {
                status = solveAcmProblem.status;
              }
              if (solveOiProblem) {
                status = solveOiProblem.status;
              }
              if (status === 0) {
                count++;
              }
            }
          }
          let percent = 0;
          if (cur.problem_step_level && cur.problem_step_level.length) {
            const levelLength = cur.problem_step_level.filter(
              (li) => li.visible
            );

            percent = ((count / levelLength.length) * 100).toFixed(0);
            if (count === 0 && levelLength.length === 0) {
              percent = 0;
            }
          }

          cur.percent = percent;
          acc.push(cur);
          // 채점하고 나서 유저 정보 업데이트를 하는 로직이 필요하다.
          return acc;
        }, []);
      
      this.category = this[this.$route.query.type];
      this.columns = [
        {
          title: "Num",
          align: "center",
          width: this.$store.state.isMobile ? 60 : 150,
          render: (h, params) => {
            return h("span", params.index + 1);
          },
        },
        {
          title: "Level",

          className: "iview-table-title",
          align: "center",
          width: this.$store.state.isMobile ? 150 : 250,
          render: (h, params) => {
            return h("span", {}, params.row.title);
          },
        },
        {
          title: "완성도",
          align: "center",
          width: this.$store.state.isMobile ? 60 : 600,
          render: (h, params) => {
            if (!this.$store.state.isMobile) {
              return h(
                "el-progress",
                {
                  props: {
                    "stroke-linecap": "square",
                    "text-inside": true,
                    percentage: Number(params.row.percent),
                    "stroke-width": 30,
                  },
                  style: {
                    padding: "12px 15px !important",
                    "text-align": "left !important",
                  },
                },
                params.row.percent
              );
            } else {
              return h("div", `${params.row.percent}%`);
            }
          },
        },
        {
          title: "내용",
          align: "center",
          width: this.$store.state.isMobile ? 150 : 300,
          render: (h, params) => {
            return h(
              "span",
              {
                style: {
                  "text-align": "left !important",
                },
              },
              params.row.content
            );
          },
        },
      ];
    },
    move(row, index) {
      this.CHANGE_PROBLEM_TITLE(row.title);
      // /problem-step/${this.$route.params}/level/${row.id}/detail
      // /problem-step/:step_id/level/:level_id/detail
      this.$router.push(
        `/problem-step/${this.$route.params.step_id}/level/${row.id}/detail`
      );
    },
    stepEdit() {
      const { step_id } = this.$route.params;
      window.open(`/admin/problem-steps/${step_id}/edit`);
    },
    levelEdit() {
      const { step_id } = this.$route.params;
      window.open(`/admin/problem-steps/${step_id}`);
    },
  
    isLoggedIn() {
      return !!(
        this.$store.state["user"]["profile"] &&
        this.$store.state["user"]["profile"].id
      );
    },
  },
  components: { BoardHighlight },
};
</script>

<style>
.el-progress-bar__inner {
  border-radius: 5px !important;
}
.el-progress-bar__outer {
  border-radius: 0px !important;
}
.ivu-table-header th {
  text-align: center !important;
}
.iview-table-title .ivu-table-cell {
  padding: 0;
}
</style>
